import './ExportsCardList.scss'
import * as React from 'react'
import { BEM } from '~/services/BEMService'
import { List } from '~/components/List'
import { ExamCandidateIntegrationCard } from '~/components/Exports/Cards/ExamCandidateIntegrationCard'
import { OutflowingIntegrationCard } from '~/components/Exports/Cards/OutflowIntegrationCard'
import { TeacherIntegrationCard } from '~/components/Exports/Cards/TeacherIntegrationCard'
import { ExamAttemptsCard } from '~/components/Exports/Cards/ExamAttemptsCard'
import { IntegrationRegistrationCard } from '~/components/Exports/Cards/IntegrationRegistrationCard'
import { ListItem } from '~/components/ListItem'
import gql from 'graphql-tag'
import { Fetcher } from '~/utils'
import { get } from 'lodash'
import { DUOBOWEnrollmentContractsExportCard } from '~/components/Exports/Cards/DUOBOWEnrollmentContractsExportCard'
import { DUOParticipatedHoursExportCard } from './Cards/DUOParticipatedHoursExportCard'
import { WI2021StudentsAndHoursExportCard } from './Cards/WI2021StudentsAndHoursExportCard'
import { TeachersHoursExportCard } from './Cards/TeachersHoursExportCard'
import { getCurrentUser, viewerHasRole } from '~/services/session'
import { UserRole } from '~/types/User'

interface Props {}

const EXPORT_LAST_DATES_QUERY = gql`
    query _ {
        exports_lastDates {
            EnrollmentCitizenship
            OutflowCitizenship
            TeachersCitizenship
            FinalexamAttempt
            DUOLearnerReport
            DUOBOWEnrollmentContracts
            DUOParticipatedHours
            WI2021StudentsAndHours
            TeachersHours
        }
    }
`

export class ExportsCardLists extends React.Component<Props> {
    private bem = new BEM('ExportsCardList', () => ({}))

    private lastDatesFetcher: Fetcher

    constructor(props: Props) {
        super(props)
        this.lastDatesFetcher = new Fetcher({
            query: EXPORT_LAST_DATES_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { data } = this.lastDatesFetcher
        const lastDates = get(data, 'exports_lastDates')
        const currentUser = getCurrentUser()

        return (
            <List horizontal={true} className={this.bem.getClassName()}>
                <ListItem>
                    <ExamCandidateIntegrationCard
                        refreshDates={this.onSubmitSuccess}
                        lastDate={get(lastDates, 'EnrollmentCitizenship')}
                    />
                </ListItem>
                <ListItem>
                    <OutflowingIntegrationCard
                        refreshDates={this.onSubmitSuccess}
                        lastDate={get(lastDates, 'OutflowCitizenship')}
                    />
                </ListItem>
                <ListItem>
                    <TeacherIntegrationCard
                        refreshDates={this.onSubmitSuccess}
                        lastDate={get(lastDates, 'TeachersCitizenship')}
                    />
                </ListItem>
                <ListItem>
                    <ExamAttemptsCard
                        refreshDates={this.onSubmitSuccess}
                        lastDate={get(lastDates, 'FinalexamAttempt')}
                    />
                </ListItem>
                <ListItem>
                    <IntegrationRegistrationCard
                        refreshDates={this.onSubmitSuccess}
                        lastDate={get(lastDates, 'DUOLearnerReport')}
                    />
                </ListItem>
                <ListItem>
                    <DUOBOWEnrollmentContractsExportCard
                        refreshDates={this.onSubmitSuccess}
                        lastDate={get(lastDates, 'DUOBOWEnrollmentContracts')}
                    />
                </ListItem>
                <ListItem>
                    <DUOParticipatedHoursExportCard
                        refreshDates={this.onSubmitSuccess}
                        lastDate={get(lastDates, 'DUOParticipatedHours')}
                    />
                </ListItem>
                <ListItem>
                    <WI2021StudentsAndHoursExportCard
                        refreshDates={this.onSubmitSuccess}
                        disableAutomaticStudentSearch={!currentUser.isAdmin}
                        lastDate={get(lastDates, 'WI2021StudentsAndHours')}
                    />
                </ListItem>
                {currentUser && (currentUser.isAdmin || viewerHasRole(UserRole.HREmployee)) && (
                    <ListItem>
                        <TeachersHoursExportCard
                            refreshDates={this.onSubmitSuccess}
                            lastDate={get(lastDates, 'TeachersHours')}
                        />
                    </ListItem>
                )}
            </List>
        )
    }

    private onSubmitSuccess = () => {
        this.lastDatesFetcher.refetch()
    }
}
